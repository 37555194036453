import React, { useLayoutEffect, useEffect, useState } from "react"
import parse from "html-react-parser"
import Sticky from 'react-sticky-el'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SingleEventRegisterButton from "../../components/EventCards/SingleEventRegisterButton"
import ShareTools from "../../components/socialLinks"
import QtLevel1Testimonials from "../../fixtures/testimonial-qt-level-1"
import QtLevel2Testimonials from "../../fixtures/testimonial-qt-level-2"
import QtSCHTestimonials from "../../fixtures/testimonial-self-created-health"
import QtEnergBoostTestimonials from "../../fixtures/testimonial-qt-energy-boost"
import TestimonialListItem from "../../components/testimonialListItem"
import SingleEventCard from "../../components/EventCards/SingleEventCard"
import Events from "../../fixtures/workshops/all"
import Level1Data from "../../fixtures/workshops/level-1-overview"
import Level2Data from "../../fixtures/workshops/level-2-overview"
import SuperchargingData from "../../fixtures/workshops/supercharging-overview"
import SelfCreatedHealthData from "../../fixtures/workshops/sch-overview"
import EnergyBoostData from "../../fixtures/workshops/energy-boost-overview"
import BecomingMoreData from "../../fixtures/workshops/becoming-more-overview"
import ManifestingMiraclesData from "../../fixtures/workshops/manifesting-miracles-overview"
import {
  Article,
  ArticleAside,
  ArticleSection,
} from "../../styledComponents/article"

import Layout from "../../components/layout"
import { SectionEvents } from "../../styledComponents/sectionEvents"
import FormEventRegisterDates from "../../components/formEventRegisterDates"
import EventList from "../../components/EventList"
import SEO from "../../components/seo"


const QuantumTouch = ({ data, location }) => {
  const [eventDate, setEventDate] = useState(false)
  const [countryCode, setCountryCode] = useState("") // store token  
  const [dateString, setDataFromChild] = useState("")
  const [eventType, setEventTypeFromChild] = useState("")
  const [eventTitle, setEventTitleFromChild] = useState()
  const [eventExcerpt, setEventExcerpt] = useState()
  const [eventLocation, setEventLocationFromChild] = useState()
  const [eventId, setEventIdFromChild] = useState()
  const [tabIndex, setTabIndex] = useState(0);
  let allevents = Events
  let eventContent = Level1Data
  let eventTestimonials = null
  let eventDatesTimes = null
  let level1Testimonials
  if (eventType === 'qt-level-1') {
    eventContent = Level1Data
    eventTestimonials = QtLevel1Testimonials
  } else if (eventType === 'qt-level-2') {
    eventContent = Level2Data
    eventTestimonials = QtLevel2Testimonials
  } else if (eventType === 'qt-supercharging') {
    eventContent = SuperchargingData
  } else if (eventType === 'qt-manifesting-miracles') {
    eventContent = ManifestingMiraclesData
  } else if (eventType === 'qt-self-created-health') {
    eventContent = SelfCreatedHealthData
    eventTestimonials = QtSCHTestimonials
  } else if (eventType === 'qt-energy-boost') {
    eventContent = EnergyBoostData
    eventTestimonials = QtEnergBoostTestimonials
    level1Testimonials = QtLevel1Testimonials
  } else if (eventType === 'qt-becoming-more') {
    eventContent = BecomingMoreData
  }

  function handleDataFromChild(data) {
    {
      allevents.map((post, index) => {
        if (post.id === data) {
          setEventDate(post.eventsMetaData.datetime)
          setEventTitleFromChild(post.title)
          setEventTypeFromChild(post.eventType)
          setEventLocationFromChild(post.eventsMetaData.location)
          setEventIdFromChild(post.id)
          setEventExcerpt(post.excerpt)
          setTabIndex(0)
        }
      })
    }
  }

  useEffect(() => {
    fetch('/geolocation', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (geoLocationData) {
        setCountryCode(geoLocationData?.geo?.country?.code)
      })
  }, [])

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const paramEventID = urlParams.get('id')
      if (paramEventID !== null) {
        handleDataFromChild(paramEventID)
      } else if (location.state !== null && location.state.datetime !== 'no-set-date') {
        setEventDate(location.state.datetime)
        setEventTypeFromChild(location.state.eventType)
        setEventTitleFromChild(location.state.eventTitle)
        setEventLocationFromChild(location.state.eventLocation)
        setEventIdFromChild(location.state.eventId)
      }
      return
    }
  }, [])
  return (
    <>
      <Layout displayNavBorder location={location}>
        <SEO
          title={eventTitle}
          description={eventExcerpt}
        // url={data.wpPage.uri}
        />
        <SectionEvents>
          <Article
            className="event-post"
            itemScope
            itemType="http://schema.org/Article"
            id="single-event"
          >
            <h2 itemProp="headline" hidden>Karina Grant Events &amp; Workshops </h2>
            {allevents.map((post, index) => {
              if (post.id === eventId) {
                const shareURL = `https://${process.env.GATSBY_DOMAIN_NAME}` + post.uri
                return (
                  <>
                    <SingleEventCard
                      sendDataToParent={handleDataFromChild}
                      hasEventDate={eventDate}
                      datetime={eventDate}
                      eventType={eventType}
                      eventTitle={eventTitle}
                      eventId={eventId}
                      eventLocation={eventLocation}
                      excerpt={post.excerpt}
                      priceSale={post.eventsMetaData.priceSale}
                      price={post.eventsMetaData.price}
                      repeatHalfPrice={post.eventsMetaData.price / 2}
                      currency={'£'}
                      lastFewDaysEarlyBird={post.eventsMetaData.lastfewdaysearlybird}
                      lastFewSpaces={post.eventsMetaData.lastspaces}
                      timeZone={post.eventsMetaData.timezone}
                      stripeUrl={post.eventsMetaData.stripeUrl}
                      stripeUrlEarlyBird={post.eventsMetaData.stripeUrlEarlyBird}
                      stripeUrlDeposit={post.eventsMetaData.stripeUrlDeposit}
                      stripeUrlRepeat={post.eventsMetaData.stripeUrlRepeat}
                      locationaPathname={location.pathname}
                    />

                    <ArticleSection itemProp="articleBody" className="event-single">
                      <div className="tabslist">
                        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} id="tabs">
                          <Sticky boundaryElement=".tabslist" hideOnBoundaryHit={true}>
                            <TabList>
                              <Tab>Overview</Tab>
                              {post.eventsMetaData.priceSale !== null && <Tab>Dates &amp; Times</Tab>}
                              {post.eventsMetaData.location !== 'Zoom' && <Tab>Location</Tab>}
                              {post.eventsMetaData.location !== 'Zoom' && post.eventsMetaData.location !== 'Occidental Costa Cancún' && <Tab>Travel &amp; Transport</Tab>}
                              {eventTestimonials !== null && <Tab>Testimonials</Tab>}
                              {post.eventsMetaData.lunch && <Tab>Lunch</Tab>}
                            </TabList>
                          </Sticky>
                          <TabPanel>
                            <> {parse(eventContent[0].content)}</>
                          </TabPanel>
                          {post.eventsMetaData.priceSale !== null && <TabPanel>
                            <>
                              <h2>Dates & Times:</h2>
                              {parse(post.datestimes)}
                            </>
                          </TabPanel>
                          }
                          {console.log('post.eventsMetaData.location', post.eventsMetaData.location)}
                          {post.eventsMetaData.location !== 'Zoom' && post.eventsMetaData.location === 'Occidental Costa Cancún'?
                            <TabPanel>
                              <h2>Workshop location:</h2>
                              <p style={{ 'marginBottom': '1.6rem' }}>
                                <strong>Address:</strong> Occidental Costa Cancún
                              </p>
                              <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="450" allowfullscreen="" loading="lazy" id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.180108223292!2d-86.79161132458934!3d21.145229483767768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2925bcb05d65%3A0xec108f18298e3e0d!2sOccidental%20Costa%20Canc%C3%BAn!5e0!3m2!1sen!2suk!4v1711661687968!5m2!1sen!2suk" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" data-gtm-yt-inspected-10="true"></iframe><style>.mapoutermargin-top:1.6rem;position:relative;text-align:right;height:500px;width:100%;</style><style>.gmap_canvas overflow:hidden;background:none!important;height:450px;width:100%;</style></div></div>
                            </TabPanel>
                            : post.eventsMetaData.location === 'Healing House' ?
                            <TabPanel>
                              <h2>Workshop location:</h2>
                              <p style={{ 'marginBottom': '1.6rem' }}>
                                <strong>Address:</strong> 99 Truro Road, Bounds Green, London, N22 8DH
                              </p>
                              <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="450" allowfullscreen="" loading="lazy" id="gmap_canvas" src="https://www.google.com/maps/embed/v1/place?q=99+Truro+Rd,+London+N22+8DH,+England&amp;key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" data-gtm-yt-inspected-10="true"></iframe><style>.mapoutermargin-top:1.6rem;position:relative;text-align:right;height:500px;width:100%;</style><style>.gmap_canvas overflow:hidden;background:none!important;height:450px;width:100%;</style></div></div>
                            </TabPanel>
                            :
                            <></>
                          }
                          {post.eventsMetaData.location !== 'Zoom' &&
                            <TabPanel>
                              <h2 class="wp-block-heading">Travel &amp; Transport:</h2><h3 class="wp-block-heading">Travelling by train:</h3><p>The venue Healing House is close to <strong>4 train lines</strong>:</p><ul><li>Bowes Park Train Station – Great Northern Rail, Myddleton Rd, Greater London N22 4NT (8 min walk) </li><li>Bounds Green Underground, Bounds Green Rd, London N11 2EU (9 min walk) </li><li>Wood Green Underground, Wood Green, London N22 8HH (14 min walk) </li><li>Alexandra Palace Train Station – Great Northern Rail (11 min walk)</li></ul><h3 class="wp-block-heading">Traveling by car:</h3><p>There is enough parking onsite for 8 cars, if you are driving please can you let Tracy know so she can reserve you a parking space. </p><p>It is a 10 minute drive from the North Circular.</p><h2 class="wp-block-heading">Hotels:</h2><div class="wp-block-group is-layout-constrained"><div class="wp-block-group__inner-container"><p>The Premier Inn London New Southgate hotel is a 20 minute walk to Healing House</p><p>Address: Ladderswood Way, Arnos Grove, London N11 1FH</p></div></div><p>Travelodge London Wood Green is a 15 minute walk to Healing House</p>
                            </TabPanel>
                          }
                          {eventTestimonials !== null && <TabPanel>
                            <ul className="testimonials">
                              {eventTestimonials &&
                                <>
                                  {
                                    eventTestimonials.map((quotes, index) => {
                                      return (
                                        <TestimonialListItem quotes={quotes} />
                                      )
                                    })
                                  }
                                </>
                              }
                            </ul>

                            {eventType === 'qt-energy-boost' && 
                              <>
                              <h2>Testimonials from Level 1 workshop</h2>
                              <ul className="testimonials">
                              {level1Testimonials &&
                                <>
                                  {
                                    level1Testimonials.map((quotes, index) => {
                                      return (
                                        <TestimonialListItem quotes={quotes} />
                                      )
                                    })
                                  }
                                </>
                              }
                              </ul>
                              </>
                            }
                          </TabPanel>}
                          {post.eventsMetaData.lunch && <TabPanel>
                            <h2>Lunch</h2>
                            <p>The venue can provide a delicious plant based lunch on both days. There is a small £12 per day cost for a healing vegan lunch and this also includes all refreshments. Please let us know if you wish to have lunch with the group at the venue send Tracy an <a href="mailto:tracy@karinagrant.co.uk?subject=2024-10-28_09:30:00 lunch">email</a> or <a href="https://api.whatsapp.com/send/?app_absent=0&amp;phone=447457405084">WhatsApp</a> message.</p>
                          </TabPanel>}
                        </Tabs>
                      </div>
                      <ShareTools shareTitle="Share this on" pageLocation={shareURL} postTitle={post.title} />
                    </ArticleSection>
                    <ArticleAside background="var(--light-green-darker)" border="none" marginTop>
                      <h2 className="other-event-header">Other <abbr title="Quantum-Touch">QT</abbr> Workshops</h2>
                      <FormEventRegisterDates sendDataToParent={handleDataFromChild} />
                    </ArticleAside>
                  </>
                )
              }
              else {
                if ((index === 0) && (eventId === undefined)) {
                  return (
                    <ArticleAside setFullWidth background="var(--light-green-darker)" border="none" marginTop>
                      <h2 className="other-event-header">Please select a workshop</h2>
                      <FormEventRegisterDates sendDataToParent={handleDataFromChild} />
                    </ArticleAside>
                  )
                }
              }
            })}

          </Article>
        </SectionEvents>
      </Layout>
    </>
  )
}

export default QuantumTouch